import {
  DELIVERIES_TRANSPORT_STEP_KINDS,
  DeliveryItem,
  filterShipmentsByTransportStepkind,
  isMasterCarton,
  MASTER_CARTON_TRANSPORT_STEP_KIND,
  type Address,
  type Deliveries,
  type Delivery,
  type DeliveryType,
  type Shipment
} from '@/api/parcelCore'
import { SuiteButton, SuiteFlag, type SuiteTableNestedRow } from '@shipcloud/suite-components'
import { markRaw } from 'vue'
import { useI18n } from 'vue-i18n'
import type { createRouter } from 'vue-router'
import { milestoneCaption } from './milestoneCaption'

const rawComponentSuiteButton = markRaw(SuiteButton)
const rawComponentSuiteFlag = markRaw(SuiteFlag)

const formatDate = (date: string, i18n: ReturnType<typeof useI18n>): string => {
  try {
    return i18n.d(date)
  } catch {
    return ''
  }
}

const formatRecipient = (address: Address): string =>
  [address.addressLine1, address.postalCode, address.city].join(' ')

const getRowCells = (
  delivery: Delivery,
  renderedShipment: Shipment,
  i18n: ReturnType<typeof useI18n>,
  router: ReturnType<typeof createRouter>,
  isChild: boolean = false
) => {
  const masterCarton = isMasterCarton(renderedShipment)
  const toDelivery = () => {
    if (masterCarton)
      router.push({ name: 'master-carton-delivery-details', params: { id: delivery.id } })
    else router.push({ name: 'delivery-details', params: { id: delivery.id } })
  }
  const trackingNumber = renderedShipment.shipmentItem?.trackingNumber

  return [
    trackingNumber && isChild
      ? {
          content: trackingNumber,
          class: 'ps-6'
        }
      : trackingNumber,
    delivery.deliveryNumber,
    milestoneCaption(renderedShipment.shipmentItem?.events?.[0], i18n),
    renderedShipment.carrierName,
    renderedShipment.productName,
    formatDate(delivery.createdAt, i18n),
    formatRecipient(delivery.to),
    {
      props: { country: delivery.to.countryCode },
      component: rawComponentSuiteFlag
    },
    {
      component: rawComponentSuiteButton,
      props: {
        icon: masterCarton ? 'Package' : 'ZoomIn',
        onClick: toDelivery,
        'data-identifier': 'details-link'
      }
    }
  ]
}

const findShipmentOfDeliveryItem = (
  deliveryItem: DeliveryItem,
  deliveryType: DeliveryType
): Shipment | undefined => {
  let shipments: Shipment[] = []
  if (deliveryType == 'masterCarton')
    shipments = filterShipmentsByTransportStepkind(deliveryItem.shipments, [
      MASTER_CARTON_TRANSPORT_STEP_KIND
    ])

  if (!shipments.length)
    shipments = filterShipmentsByTransportStepkind(
      deliveryItem.shipments,
      DELIVERIES_TRANSPORT_STEP_KINDS
    )

  return shipments[0]
}

/**
 * Build the SuiteTableNested body object for the Deliveries table
 * @see https://app.shortcut.com/shipcloud/epic/34030#activity-37791
 */
export const deliveriesToSuiteTableNestedBody = (
  deliveries: Deliveries,
  deliveryType: DeliveryType,
  i18n: ReturnType<typeof useI18n>,
  router: ReturnType<typeof createRouter>
) => {
  /* istanbul ignore next -- @preserve */
  if (!deliveries.length) return undefined

  const { t } = i18n

  return deliveries.reduce((acc, delivery) => {
    const { deliveryItems } = delivery

    /* istanbul ignore next -- @preserve */
    if (!deliveryItems.length) return acc

    const shipments = deliveryItems.reduce((acc, deliveryItem) => {
      const shipment = findShipmentOfDeliveryItem(deliveryItem, deliveryType)
      return shipment ? [...acc, shipment] : acc
    }, [] as Shipment[])

    if (shipments.length) {
      const cells: SuiteTableNestedRow['cells'] = getRowCells(delivery, shipments[0], i18n, router)
      let children: SuiteTableNestedRow['children'] = []

      if (shipments.length > 1) {
        cells[0] = t('ParcelCore.multipleDelivery')
        children = shipments.map((shipment) => ({
          cells: getRowCells(delivery, shipment, i18n, router, true)
        }))
      }
      acc.push({
        attrs: { class: 'hover:bg-sky-10' },
        cells,
        children
      })
    }

    return acc
  }, [] as SuiteTableNestedRow[])
}
