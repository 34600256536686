import type { AxiosInstance } from 'axios'
import axios from 'axios'

import { type RestParams } from '.'

export const client: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_LAYER_API_HOST,
  timeout: 35000,
  responseType: 'json'
})

export const apiCall = async (restParams: RestParams) => {
  try {
    const { data, status } = await client(restParams.path, {
      method: restParams.method,
      data: restParams.data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${restParams.token}`
      }
    })
    return status == 200 ? data : { status }
  } catch (error: any) {
    return { status: 0 }
  }
}
