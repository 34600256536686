<script setup lang="ts">
import type { Address } from '@/api/shipcloud/address'
import { NewPickupRequest } from '@/api/shipcloud/pickupRequests'
import { BackLink } from '@/components'
import { trackingStatusColors } from '@/helpers'
import { countries as countriesObject } from '@/i18n'
import { useCarriersStore } from '@/stores/carriers'
import { usePickupRequestsStore } from '@/stores/pickupRequests'
import { useShipmentsStore } from '@/stores/shipments'
import {
  StatusCaption,
  SuiteErrorMessage,
  SuiteLoader,
  SuitePickupRequestEditor,
  type SuitePickupRequestEditorProps,
  type SuitePickupRequestEditorStateFields
} from '@shipcloud/suite-components'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const props = defineProps<{ carrier: string }>()

const { d, t } = useI18n()

const router = useRouter()
const carrierStore = useCarriersStore()
const pickupRequestStore = usePickupRequestsStore()
const shipmentStore = useShipmentsStore()

const explanation: SuitePickupRequestEditorProps['explanation'] = {
  heading: t('Office.PickupRequestsForm.Explanation.Heading'),
  lines: [
    {
      term: t('Office.PickupRequestsForm.Explanation.Line1Term'),
      content: t('Office.PickupRequestsForm.Explanation.Line1Content')
    },
    {
      term: t('Office.PickupRequestsForm.Explanation.Line2Term'),
      content: t('Office.PickupRequestsForm.Explanation.Line2Content')
    },
    {
      term: t('Office.PickupRequestsForm.Explanation.Line3Term'),
      content: t('Office.PickupRequestsForm.Explanation.Line3Content')
    },
    {
      term: t('Office.PickupRequestsForm.Explanation.Line4Term'),
      content: t('Office.PickupRequestsForm.Explanation.Line4Content')
    },
    {
      term: t('Office.PickupRequestsForm.Explanation.Line5Term'),
      content: t('Office.PickupRequestsForm.Explanation.Line5Content')
    },
    {
      term: t('Office.PickupRequestsForm.Explanation.Line6Term'),
      content: t('Office.PickupRequestsForm.Explanation.Line6Content')
    },
    {
      term: t('Office.PickupRequestsForm.Explanation.Line7Term'),
      content: t('Office.PickupRequestsForm.Explanation.Line7Content')
    }
  ]
}

watch(
  () => props.carrier,
  () => {
    if (props.carrier) {
      if (!carrierStore.loading) {
        carrierStore.fetchCarriers()
      }

      if (!shipmentStore.loading) {
        shipmentStore.fetchShipments(
          { carrier: props.carrier, to_be_picked_up: '1' },
          { page: 1, per_page: 50 }
        )
      }
    }
  },
  { immediate: true }
)

const noShipmentsFound = computed(
  () => !shipmentStore.loading && Object.keys(shipmentStore.shipments).length < 1
)

const activeCarrier = computed(() => {
  if (!carrierStore.carrierSupportsPickupRequests(props.carrier)) return
  return carrierStore.carriers?.[props.carrier]
})

const loading = computed(() => shipmentStore.loading || carrierStore.loading)

const formProps = computed(() => {
  if (!activeCarrier.value || noShipmentsFound.value) return undefined

  return {
    itemsHeadings: [
      t('Office.PickupRequestsForm.ShipmentListHeaders.ShipmentNumber'),
      t('Office.PickupRequestsForm.ShipmentListHeaders.CarrierTrackingNumber'),
      t('Office.PickupRequestsForm.ShipmentListHeaders.Timestamp'),
      t('Office.PickupRequestsForm.ShipmentListHeaders.TrackingStatus')
    ],
    panels: [
      {
        title: t('Office.PickupRequestsForm.From.Title', {
          carrier: activeCarrier.value?.display_name
        }),
        subtitle: t('Office.PickupRequestsForm.From.Subtitle'),
        fields: [
          { fieldName: 'from.company', fieldType: 'text', value: '', label: t('Address.company') },
          {
            fieldName: 'from.first_name',
            fieldType: 'text',
            value: '',
            label: t('Address.first_name')
          },
          {
            fieldName: 'from.last_name',
            fieldType: 'text',
            value: '',
            label: t('Address.last_name')
          },
          { fieldName: 'from.care_of', fieldType: 'text', value: '', label: t('Address.care_of') },
          { fieldName: 'from.street', fieldType: 'text', value: '', label: t('Address.street') },
          {
            fieldName: 'from.street_no',
            fieldType: 'text',
            value: '',
            label: t('Address.street_no')
          },
          {
            fieldName: 'from.zip_code',
            fieldType: 'text',
            value: '',
            label: t('Address.zip_code')
          },
          { fieldName: 'from.city', fieldType: 'text', value: '', label: t('Address.city') },
          {
            fieldName: 'from.country',
            fieldType: 'combobox',
            value: '',
            label: t('Address.country'),
            props: {
              options: Object.entries(countriesObject).map(([value, label]) => ({ label, value }))
            }
          },
          { fieldName: 'from.email', fieldType: 'text', value: '', label: t('Address.email') },
          { fieldName: 'from.phone', fieldType: 'text', value: '', label: t('Address.phone') }
        ]
      },
      {
        title: t('Office.PickupRequestsForm.Time.Title', {
          carrier: activeCarrier.value?.display_name
        }),
        subtitle: t('Office.PickupRequestsForm.Time.Subtitle'),
        fields: [
          {
            fieldName: 'time.date',
            fieldType: 'date',
            value: '',
            label: t('Office.PickupRequestsForm.Time.Fields.Date')
          },
          {
            fieldName: 'time.earliest',
            fieldType: 'time',
            value: '',
            label: t('Office.PickupRequestsForm.Time.Fields.Earliest')
          },
          {
            fieldName: 'time.latest',
            fieldType: 'time',
            value: '',
            label: t('Office.PickupRequestsForm.Time.Fields.Latest')
          }
        ]
      }
    ],
    itemsTitle: t('Office.PickupRequestsForm.ShipmentList.Title'),
    itemsSubtitle: t('Office.PickupRequestsForm.ShipmentList.Subtitle'),
    itemsRows: Object.values(shipmentStore.shipments).map((shipment) => {
      const status = shipment.packages?.[0]?.tracking_events?.[0]?.status
      return [
        shipment.id,
        shipment.carrier_tracking_no,
        d(shipment.created_at, 'long'),
        {
          content: t(`TrackingEvent.status.${status}`),
          props: { color: trackingStatusColors(status) },
          component: StatusCaption
        }
      ]
    }),
    strButtonCancel: t('Office.PickupRequestsForm.ShipmentListHeaders.ButtonCancel'),
    strButtonSubmit: t('Office.PickupRequestsForm.ShipmentListHeaders.ButtonSubmit'),
    explanation
  } as SuitePickupRequestEditorProps
})

// @TODO Move this definition to suite-components
export interface SuitePickupRequestEditorState {
  fields: SuitePickupRequestEditorStateFields
  items: SuitePickupRequestEditorProps['itemsRows']
}
const onSubmit = async (payload: SuitePickupRequestEditorState) => {
  const getPickupTimeString = (date: string, time: string) => {
    if (!date || !time) return ''
    const dateObj = new Date([date, time].join(' '))
    return isNaN(dateObj.valueOf()) ? '' : dateObj.toISOString()
  }

  const pickup_address = Object.entries(payload.fields).reduce(
    (acc, [fieldName, fieldValue]) => {
      if (fieldName.indexOf('from.') === 0 && !!fieldValue) {
        acc = acc || {}
        Object.assign(acc, { [fieldName.replace('from.', '')]: fieldValue })
      }
      return acc
    },
    undefined as Address | undefined
  )

  const pickup_time: NewPickupRequest['pickup_time'] = {
    earliest: getPickupTimeString(payload.fields['time.date'], payload.fields['time.earliest']),
    latest: getPickupTimeString(payload.fields['time.date'], payload.fields['time.latest'])
  }

  const shipments: NewPickupRequest['shipments'] = payload.items
    ? payload.items.map((item) => ({
        id: item[0]
      }))
    : []

  const newPickupRequest: NewPickupRequest = {
    carrier: props.carrier,
    pickup_address,
    pickup_time,
    shipments
  }

  const id = await pickupRequestStore.createPickupRequest(newPickupRequest)

  if (id) {
    router.push({
      name: 'pickup-requests-details',
      params: { id }
    })
  }
}

const errorPanelPresent = computed(
  () => !pickupRequestStore.loading && !pickupRequestStore.saving && pickupRequestStore.error
)
</script>

<template>
  <SuiteLoader :loading class="h-full">
    <SuiteErrorMessage :scroll-into-view="true" v-if="errorPanelPresent" class="rounded-none">
      <div class="mx-auto max-w-(--breakpoint-2xl) px-8">
        {{ t('Office.ShipmentForm.error') }}
        <div v-for="err in pickupRequestStore.error?.errors" :key="err">
          {{ err }}
        </div>
      </div>
    </SuiteErrorMessage>
    <SuitePickupRequestEditor v-if="formProps" v-bind="formProps" @submit="onSubmit">
      <template #header>
        <BackLink name="pickup-requests-dashboard" />
      </template>
    </SuitePickupRequestEditor>
    <SuiteErrorMessage v-if="!(formProps || errorPanelPresent)" class="rounded-none">
      {{ t('App.Error.noShipmentsToPickupError') }}
    </SuiteErrorMessage>
  </SuiteLoader>
</template>
