<script setup lang="ts">
import { createDocument, DeliveryDocument, DOCUMENT_TYPES, DocumentUpload } from '@/api/apiLayer'
import { fileExtension, readFileAsBase64 } from '@/helpers'
import { useAuthenticationV2Store } from '@/stores/authenticationV2'
import {
  SuiteButton,
  SuiteDialogBox,
  SuiteInput,
  SuiteInputLabel,
  SuiteSelect
} from '@shipcloud/suite-components'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

type DocumentUploadDialogProps = { deliveryId: string }
const props = defineProps<DocumentUploadDialogProps>()
const emit = defineEmits<{
  (e: 'success', message: string): void
  (e: 'failure', message: string): void
}>()

const { t } = useI18n()
const dialogOpen = ref(false)
const authenticationStore = useAuthenticationV2Store()
const defaultDocument = {
  type: 'ADR_SLIP',
  format: 'zpl',
  content: ''
} as const
const document = ref<DeliveryDocument>(defaultDocument)
const fileInput = ref()
const documentTypeOptions = DOCUMENT_TYPES.map((dt) => ({
  label: t(`DocumentTypes.${dt}`, dt),
  value: dt
}))
const footerButtonSubmitText = computed(() =>
  document.value?.content.length ? t('DeliveryDocument.submit') : t('DeliveryDocument.uploadHint')
)

const processingFilePromise = ref<Promise<null>>()
const fileChanged = async (event: Event) => {
  const file = (event.target as HTMLInputElement).files?.[0]
  if (!file) return

  processingFilePromise.value = new Promise<null>((resolve, reject) => {
    try {
      readFileAsBase64(file).then((content) => {
        document.value.content = content
        document.value.format = fileExtension(file.name) || defaultDocument.format
        resolve(null)
      })
    } catch (error) {
      console.error('Error reading file:', error)
      reject()
    }
  })
}
const uploadDocument = async () => {
  if (!document.value?.content.length) return false

  const documentUpload = DocumentUpload.parse({
    entityType: 'Delivery',
    entityId: props.deliveryId,
    documents: [document.value]
  })
  dialogOpen.value = false
  document.value.content = ''
  fileInput.value = null
  const response = await createDocument(authenticationStore.token, documentUpload)
  if ('requestId' in response) emit('success', t('DeliveryDocument.uploadSuccess'))
  else emit('failure', t('DeliveryDocument.uploadFailure'))
}
defineExpose({ processingFilePromise }) // necessary for testing
</script>

<template>
  <SuiteButton
    id="open-document-upload-dialog"
    @click="dialogOpen = true"
    :title="t('DeliveryDocument.uploadDocuments')"
    icon="Upload"
  />
  <SuiteDialogBox
    id="document-upload-dialog"
    v-model="dialogOpen"
    @submit="uploadDocument"
    :footerButtonCancelText="t('Office.ShippingRules.close')"
    :footerButtonSubmitText
  >
    <template #header>
      {{ t('DeliveryDocument.uploadDocuments') }}
    </template>
    <div class="space-y-2">
      <SuiteInputLabel label-position="top">
        <template #label>{{ t('DeliveryDocument.documentType') }}</template>
        <SuiteSelect
          id="select-document-type"
          :placeholder="t('DeliveryDocument.documentType')"
          :options="documentTypeOptions"
          v-model="document.type"
          class="w-full"
        />
      </SuiteInputLabel>
      <SuiteInputLabel label-position="top">
        <template #label>{{ t('DeliveryDocument.content') }}</template>
        <SuiteInput id="document-file" v-model="fileInput" type="file" @change="fileChanged" />
      </SuiteInputLabel>
    </div>
  </SuiteDialogBox>
</template>
