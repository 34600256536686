import type { Address, Delivery } from '@/api/parcelCore'
import { decodeHtmlCharacters } from '@/helpers'
import type { SuiteItemDetailsProps } from '@shipcloud/suite-components'
import type { useI18n } from 'vue-i18n'

const formatAddress = (address: Address) =>
  [
    address.addressLine1,
    address.addressLine2,
    address.addressLine3,
    address.street,
    address.postalCode,
    address.city
  ]
    .map((s) => decodeHtmlCharacters(decodeHtmlCharacters(s)))
    .filter((s) => !!s)
    .join('\n')

export const deliveryToSuiteItemDetailsDetails = (
  delivery: Delivery | undefined,
  i18n: ReturnType<typeof useI18n>,
  masterCarton = false
): SuiteItemDetailsProps['details'] => {
  if (!delivery) return undefined

  const { t } = i18n

  const columns: Required<SuiteItemDetailsProps>['details']['columns'] = [
    {
      title: masterCarton ? t('ParcelCore.masterCartonDetails') : t('ParcelCore.deliveryDetails'),
      items: [
        {
          label: t('Shipment.from'),
          value: formatAddress(delivery.from)
        }
      ]
    }
  ]

  if (!masterCarton) {
    columns.push({
      title: t('Office.ShipmentDetails.customerDetails'),
      items: [
        {
          label: t('Shipment.to'),
          value: formatAddress(delivery.to)
        }
      ]
    })
  }

  return { columns }
}
