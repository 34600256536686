<script setup lang="ts">
import { isMasterCarton, type Shipment } from '@/api/parcelCore'
import { showWipFeatures } from '@/helpers'
import {
  SuiteButton,
  SuiteCarrierLogo,
  SuiteEventTimeline,
  ThemeEnum,
  type SuiteCarrierLogoProps,
  type SuiteEventTimelineEvent
} from '@shipcloud/suite-components'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    shipment: Shipment
    showDetails?: boolean
  }>(),
  { showDetails: true }
)

const { d, t } = useI18n()

const emit = defineEmits(['viewMasterCartonDelivery'])

const events = computed(() => {
  if (!props.shipment.shipmentItem?.events?.length) return undefined
  return props.shipment.shipmentItem.events.map(
    (event: any, eventIndex) =>
      ({
        timestamp: d(event.timestamp, 'long'),
        title: event.status,
        status: event.status,
        body: event.location,
        id: `${props.shipment.id}-${eventIndex + 1}`
      }) as SuiteEventTimelineEvent
  )
})

const carrierLogoProps = computed(() => {
  const carrierMapping: Record<string, SuiteCarrierLogoProps['carrier']> = {
    SEND_ES: 'sending',
    UPS_DE: 'ups',
    UPS_NL: 'ups'
  }

  if (!carrierMapping?.[props.shipment.carrierCode]) {
    return undefined
  }

  return {
    carrier: carrierMapping[props.shipment.carrierCode],
    alt: props.shipment.carrierName
  } as SuiteCarrierLogoProps
})
</script>

<template>
  <div>
    <div class="flex items-center gap-4" v-if="showDetails">
      <SuiteCarrierLogo v-if="carrierLogoProps" v-bind="carrierLogoProps" class="size-16" />
      <span v-else>{{ shipment.carrierName }}</span>
      <span>{{ shipment.productName }}</span>
      <span>{{ shipment.trackingNumber }}</span>
      <SuiteButton
        v-if="showWipFeatures() && isMasterCarton(shipment)"
        @click="emit('viewMasterCartonDelivery')"
        icon="Package"
        :theme="ThemeEnum.BLUE_OUTLINE"
        data-identifier="link-master-carton-delivery-details"
      >
        {{ t('ParcelCore.masterCartonLinkCaption') }}
      </SuiteButton>
    </div>
    <SuiteEventTimeline v-if="events" :events="events" />
  </div>
</template>
