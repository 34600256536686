import { z } from 'zod'

export const Platform = z.object({
  data_reference: z.string(),
  platform_id: z.string(),
  platform_name: z.string()
})

export const TokenPayload = z
  .object({
    exp: z.number(),
    sub: z.string(),
    platforms: Platform.array()
  })
  .passthrough()

const UnparsedTokenPayload = TokenPayload.extend({ platforms: z.string() })
export type Platform = z.infer<typeof Platform>
export type TokenPayload = z.infer<typeof TokenPayload>

export const parseTokenPayload = (payload: unknown): TokenPayload | undefined => {
  try {
    const unparsedPayload = UnparsedTokenPayload.safeParse(payload)
    if (unparsedPayload.success) {
      const parsedPayload = {
        ...unparsedPayload.data,
        platforms: JSON.parse(unparsedPayload.data.platforms)
      }
      const tokenPayload = TokenPayload.safeParse(parsedPayload)
      if (tokenPayload.success) return tokenPayload.data
    }
  } catch (e) {
    console.error(e)
  }
}
