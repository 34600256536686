import { z } from 'zod'
import { ShipmentItem } from './shipmentItem'

export const ALL_TRANSPORT_STEP_KINDS = [
  'CrossdockToCrossdock',
  'DirectShipping',
  'LastMile',
  'LinehaulToDestinationCountry',
  'PickUpAndShipToCrossdock',
  'LinehaulParcel'
] as const
export type TransportStepKind = (typeof ALL_TRANSPORT_STEP_KINDS)[number]
export const DELIVERIES_TRANSPORT_STEP_KINDS: TransportStepKind[] = ['DirectShipping', 'LastMile']
export const MASTER_CARTON_TRANSPORT_STEP_KIND: TransportStepKind = 'LinehaulParcel'

export const Shipment = z.object({
  id: z.string(),
  shipmentNumber: z.string(),
  trackingNumber: z.string().optional(),
  productCode: z.string(),
  productName: z.string(),
  carrierCode: z.string(),
  carrierName: z.string(),
  transportStepKind: z.enum(ALL_TRANSPORT_STEP_KINDS).optional(),
  shipmentItem: ShipmentItem
})
export type Shipment = z.infer<typeof Shipment>

export const isMasterCarton = (shipment: Shipment) =>
  shipment.transportStepKind == MASTER_CARTON_TRANSPORT_STEP_KIND

export const filterShipmentsByTransportStepkind = (
  shipments: Shipment[],
  transportStepKinds: TransportStepKind[]
): Shipment[] => {
  return shipments.filter(
    (s) => s.transportStepKind && transportStepKinds.includes(s.transportStepKind)
  )
}
