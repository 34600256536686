<script lang="ts" setup>
import { Delivery, GetDelivery, getLastShipmentFromDelivery } from '@/api/parcelCore'
import { BackLink, SuccessMessagePanel } from '@/components'
import { DocumentUploadDialog } from '@/components/apiLayer'
import { showWipFeatures } from '@/helpers'
import { SuiteErrorMessage, SuiteItemDetails, SuiteLoader } from '@shipcloud/suite-components'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { deliveryToSuiteItemDetails } from './helpers'

type DeliveryDetailsProps = { id: string }
const props = defineProps<DeliveryDetailsProps>()

const delivery = ref<Delivery>()
const loading = ref(false)
const errorMsg = ref()
const successMsg = ref()
watch(
  props,
  async () => {
    loading.value = true
    delivery.value = undefined
    errorMsg.value = undefined

    const result = await GetDelivery(props.id)

    if ('status' in result) {
      errorMsg.value = t('App.Error.fetchDeliveryError')
    } else {
      delivery.value = result
    }
    loading.value = false
  },
  { immediate: true }
)

const router = useRouter()
const i18n = useI18n()
const { t } = i18n
const lastShipment = computed(() =>
  delivery.value ? getLastShipmentFromDelivery(delivery.value) : undefined
)
const detailsProps = computed(() =>
  deliveryToSuiteItemDetails({
    delivery: delivery.value,
    i18n,
    mainShipment: lastShipment.value,
    router
  })
)
</script>

<template>
  <SuiteLoader page :loading />
  <SuccessMessagePanel v-if="successMsg">{{ successMsg }}</SuccessMessagePanel>
  <template v-if="!loading">
    <SuiteErrorMessage class="rounded-none" v-if="errorMsg">
      {{ errorMsg }}
    </SuiteErrorMessage>
    <SuiteItemDetails v-if="detailsProps" v-bind="detailsProps">
      <template #header>
        <BackLink name="deliveries-dashboard" />
      </template>
      <template #headerSecondary v-if="delivery && showWipFeatures()">
        <div class="space-x-2 font-normal">
          <DocumentUploadDialog
            :deliveryId="delivery.id"
            @success="(msg) => (successMsg = msg)"
            @failure="(msg) => (errorMsg = msg)"
          />
        </div>
      </template>
    </SuiteItemDetails>
  </template>
</template>
